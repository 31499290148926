import React, { createContext, useContext, useEffect, useState } from "react";
import { useFetchPrivileges } from "../hooks";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(() =>
    localStorage.getItem("authToken")
  );
  const fetchPrivileges = useFetchPrivileges();

  useEffect(() => {
    if (authToken) {
      fetchPrivileges();
      console.log("fetching privileges");
    }
  }, [authToken]);

  const login = (token) => {
    localStorage.setItem("authToken", token);
    setAuthToken(token);
  };

  const logout = () => {
    fetch("https://dev2.botgauge.xyz/platform/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          console.log("Logout successful");
        } else {
          console.error("Failed to logout from the server");
        }
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });

    localStorage.removeItem("authToken");
    setAuthToken(null);
    console.log("Logged out locally");
  };

  return (
    <AuthContext.Provider value={{ authToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
