import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


const RequireAuth = ({ children }) => {
    const { authToken } = useAuth();
    const navigate = useNavigate();
 
    React.useEffect(() => {
        if (!authToken) {
        console.log("authtoken is not there")
            navigate('/login', { replace: true });
        }
    }, [authToken, navigate]);

    return authToken ? children : null; // Render children only if authenticated
};

export default RequireAuth;
