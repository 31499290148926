import apiService, { apiUrl } from "./apiServices";

export const getVideoUrl = (videoId) => {
  const authToken = localStorage.getItem("authToken");
  return `${apiUrl}/platform/video/${videoId}?auth_token=${authToken}`;
};

export const TestcaseDetails = async (id) => {
  try {
    const response = await apiService.get(
      `/platform/get_testcase_details/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Testcase data:", error);
    throw error;
  }
};

export const TestcaseData = async (id) => {
  try {
    const response = await apiService.get(
      `/platform/get_testdata_for_testcase/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Testcase data:", error);
    throw error;
  }
};

export const generateSessionId = async (testcase_id_detail) => {
  try {
    const response = await apiService.post(
      "/platform/start_generation/",
      JSON.stringify(testcase_id_detail)
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    return error?.response
      ? error?.response?.data
        ? error.response.data
        : error.response
      : error;
  }
};

export const createTestCase = async (testcase_detail) => {
  try {
    const response = await apiService.post(
      "/platform/create_testcase/",
      JSON.stringify(testcase_detail)
    );
    if (response?.data) return response.data;
    return response;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error?.response
      ? error?.response?.data
        ? error.response.data
        : error.response
      : error;
  }
};

export const createmeet = async () => {
  try {
    const response = await apiService.post(
      "/platform/create_meet/",
      
    );
    console.log(response)
    return response;
    // if (response?.data) return response.data;
    // return response;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error?.response
      ? error?.response?.data
        ? error.response.data
        : error.response
      : error;
  }
};

export const endmeet = async (meet_detail) => {
  try {
    const response = await apiService.post(
      "/platform/end_meet/", JSON.stringify(meet_detail)
    );
    console.log(response)
    return response;
    // if (response?.data) return response.data;
    // return response;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error?.response
      ? error?.response?.data
        ? error.response.data
        : error.response
      : error;
  }
};


export const loginUser = async (login_detail) => {
  try {
    const response = await apiService.post(
      "/platform/login",
      JSON.stringify(login_detail)
    );
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const deleteTestCase = async (testcaseId) => {
  try {
    const response = await apiService.post(`/platform/delete_testcase`, {
      testcase_id: testcaseId,
    });
    return response.data;
  } catch (error) {
    console.error("Error while deleting testcase:", error);
    throw error;
  }
};

export const fetchTestcasePage = async (page, search) => {
  try {
    let url = `/platform/v1/get_all_testcases/?page=${page}`;

    if (search) {
      url += `&search=${search}`;
    }

    const response = await apiService.get(url);
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

// replace Linked Local Data
export const remove_testcase_association = async (selectedKey, id_array) => {
  try {
    const url = "/platform/local-data/remove-testcase-association";
    const data = {
      local_data_id: selectedKey,
      test_case_ids: id_array,
    };

    const response = await apiService.post(url, data);
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const add_testcase_association = async (tempSelectedKey, id_array) => {
  try {
    const url = "/platform/local-data/add-testcase-association";
    const data = {
      local_data_id: tempSelectedKey,
      list_of_test_case_ids: id_array,
    };

    const response = await apiService.post(url, data);
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const fetch_Linked_Testcase_to_Data = async (localDataId) => {
  try {
    const response = await apiService.get(
      `/platform/test-cases/for-local-data/${localDataId}/`
    );
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const fetch_existing_groups = async () => {
  try {
    const response = await apiService.get(`/platform/groups/`);

    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const fetch_existing_group_by_id = async (group_id) => {
  try {
    const response = await apiService.get(`/platform/groups/${group_id}`);

    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};
export const updateTestcaseUrl = async (testcaseId, newUrl) => {
  try {
    const response = await apiService.patch(
      `/platform/testcase/${testcaseId}`,
      {
        url: newUrl,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating testcase URL:", error);
  }
};

// folder API's

export const createFolder = async (name) => {
  try {
    const postData = { folder_name: name };
    const response = await apiService.post(`/platform/folders`, postData);
    return response.data;
  } catch (error) {
    console.error("An error occurred while creating folders:", error);
    throw error;
  }
};

export const updateFolder = async (folderId, name) => {
  try {
    const postData = { folder_name: name };
    const response = await apiService.put(
      `/platform/folders/${folderId}`,
      postData
    );
    return response.data;
  } catch (error) {
    console.error("An error occurred while updating folders:", error);
    throw error;
  }
};

export const deleteFolder = async (folderId) => {
  try {
    const response = await apiService.delete(`/platform/folders/${folderId}`);
    return response.data;
  } catch (error) {
    console.error("An error occurred while deleting folders:", error);
    throw error;
  }
};

export const fetchFolders = async () => {
  try {
    const response = await apiService.get(`/platform/folders`);
    return response.data;
  } catch (error) {
    console.error("An error occurred while fetching folders:", error);
    // throw error;
  }
};

export const fetchTestcaseByFolderId = async (folderId, page, search = "") => {
  if (!folderId) return;
  try {
    let url = `/platform/test-cases?folder_id=${folderId}`;

    if (page) {
      url += `&page=${page}`;
    }

    if (search && search.length) {
      url += `&search=${search}`;
    }

    const response = await apiService.get(url);
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const searchTestCaseFolders = async (search) => {
  try {
    const response = await apiService.get(
      `/platform/folders/associated-with-test-cases?search=${search}`
    );
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
};

export const changeTestCaseFolder = async (testCaseId, folderId) => {
  try {
    const response = await apiService.patch(
      `/platform/test-cases/${testCaseId}/change-folder/`,
      null,
      {
        params: {
          new_folder_id: folderId,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return response?.data ? response?.data : response;
  } catch (error) {
    console.error("An error occurred:", error);
    throw error?.response
      ? error?.response?.data
        ? error.response.data
        : error.response
      : error;
  }
};

// export const searchFolderTestCases = async (id, search) => {
//   try {
//     let url = `/platform/test-cases?folder_id=${id}`;

//     if (search && search.length) {
//       url += `&search=${search}`;
//     }

//     const response = await apiService.get(url);
//     if (response.status !== 200) {
//       throw new Error("Network response was not ok");
//     }
//     return response.data;
//   } catch (error) {
//     console.error("An error occurred:", error);
//     throw error;
//   }
// };
