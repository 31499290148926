import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";

const DateRangePicker = ({ setStartDate, startDate, endDate, setEndDate }) => {
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <div className="parent-style-date-picker">
      <div>
        <label></label>
        <DatePicker
          className="picker"
          selected={startDate}
          onChange={handleStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          dateFormat="dd/MMM/yyyy" // Set the date format here(according to the consumer region)
        />
      </div>
      <div>
        <label> </label>
        <DatePicker
          className="picker"
          selected={endDate}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
          dateFormat="dd/MMM/yyyy" // Set the date format here(according to the consumer)
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
