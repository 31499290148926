import React, { useState, useEffect } from "react";
import logo from "../../assets/BotGauge-logo.png";
import heye from "../../assets/hidden-eye.svg";
import seye from "../../assets/show-eye.svg";
import "./login.css";
import { loginUser } from "../../services/testCaseServices";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { CircularProgress } from "@mui/material";
import { get_user_privileges } from "../../services/userPrivelegesServices";
import { connect } from "react-redux";

const LoginPage = ({ userPrivileges }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login, authToken } = useAuth();

  const [tryingToLogin, setTryingToLogin] = useState(false);

  useEffect(() => {
    if (authToken) {
      navigate("/test-case", { replace: true });
    }
  }, [authToken, navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Reset any previous error message
    const data_to_send = {
      email: email,
      password: password,
    };

    setTryingToLogin(true);

    try {
      const data = await loginUser(data_to_send);
      // console.log("login data is ", data);
      if (data && data.token) {
        login(data.token);
        if (localStorage.getItem("authToken")) {
          if (localStorage.getItem("path")) {
            navigate(localStorage.getItem("path"));
            localStorage.removeItem("path");
          } else {
            navigate("/test-case");
          }
        }
      } else {
        setError("Invalid email or password.");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError(
        "Please check your username and password. If you still can't log in, contact your BotGauge support team."
      );
    } finally {
      setTryingToLogin(false);
    }
  };

  return (
    <div className="parent-container">
      <div className="left-login">
        <div className="text-container">
          <div className="main-heading">
            <p>
              Automate Your <br /> Web App Testing
            </p>
          </div>
          <div className="sub-heading" style={{ padding: "1.5rem" }}>
            <p>
              Ultimate automatic testing framework tailored for web apps across
              multiple browsers.
            </p>
            <p>
              Ensure consistency, efficiency, and quality like never before.
            </p>
          </div>
        </div>
      </div>
      <div className="right-login">
        <div className="text-container">
          <div className="logo_and_texts">
            <img className="logo-login" src={logo} alt="logo" />
            <div className="welcome-message">
              Welcome <span className="excl-login">! </span>
            </div>
            <div className="detail-heading">
              Enter Your Credentials to access your account
            </div>
          </div>
          <form className="login-form" onSubmit={handleLogin}>
            <input
              className="input-login"
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div className="password-holder-login">
              <input
                className="input-login-password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <img
                src={showPassword ? seye : heye}
                className="toggle-visisbilty"
                alt="toggle-password-visibility"
                onClick={togglePasswordVisibility}
              />
            </div>
            {error && (
              <div className="additional-login-details">
                <div className="error-message">{error}</div>
              </div>
            )}{" "}
            {/* Render error message if present */}
            <div className="additional-login-details">
              <div className="remember-me">
                <input
                  type="checkbox"
                  className="checkbox-login"
                  id="remember-me"
                />
                <label htmlFor="remember-me"> Remember me</label>
              </div>
              <Link to="/forgot-password" className="forget-password-login">
                Forgot Password?
              </Link>
            </div>
            {tryingToLogin ? (
              <div className="login-submit-button">
                <p className="login-click-text">
                  <CircularProgress size={20} color="inherit" />
                </p>
              </div>
            ) : (
              <button type="submit" className="login-submit-button">
                <p className="login-click-text">LOGIN</p>
              </button>
            )}
          </form>
          <div className="no-member">
            Not a member yet?{" "}
            <Link className="route-to-signup" to="/signup">
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userPrivileges: state,
});

const mapDispatchToProps = {
  // fetchGlobalData,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
